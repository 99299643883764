import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { SENTRY_DSN, VERSION } from './constants/rails';

function shouldIgnoreError(error) {
  if (error instanceof AxiosError && [429, 449, 401, 403].includes(error?.status)) {
    return true;
  }

  return false;
}

if (typeof window !== 'undefined') {
  const enabled = !window.artprice?.sentry?.disabled;
  const replaySampling = window.artprice?.sentry?.replaySampling || 0.0;
  const enableReplay = replaySampling > 0.0;

  const sentryConf = {
    dsn: SENTRY_DSN,
    release: VERSION,
    environment: process.env.RAILS_ENV,
    initialScope: {
      tags: {
        bot: window.artprice?.client?.bot,
        suspicious: window.artprice?.client?.suspicious,
      },
    },
    normalizeDepth: 6,
    enabled,
    tunnel: '/tunnel',
    ignoreErrors: ['jQuery', 'Hydration', 'hydrating'],
    integrations: [Sentry.extraErrorDataIntegration()],
    transportOptions: {
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
    },
    beforeSend(event, hint) {
      if (shouldIgnoreError(hint.originalException)) {
        return null;
      }

      return event;
    },
    // integrations(integrations) {
    //   const filteredIntregrations = integrations.filter(integration => integration.name !== 'Dedupe');
    //
    //   return [...filteredIntregrations, Sentry.extraErrorDataIntegration()];
    // },
  };

  if (enableReplay) {
    sentryConf.replaysOnErrorSampleRate = replaySampling;
    sentryConf.integrations.push(
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    );
  }

  Sentry.init(sentryConf);
}
